import * as React from "react"
import Seo from "../../components/seo"
import { Link, graphql } from "gatsby"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Copyright from "../../components/copyright"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPage = ({data}) => {
  const entry = data.prismicBlogPage
  const entries = data.allPrismicBlog
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/blog/" />
      <Header />
      <div className="w-full py-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight">
                <h1>{entry.data.heading.text}</h1>
              </div>
            </div>
            <div className="w-full sm:w-4/12"></div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {entries.edges.map((entry, i) => (
              <div className="group" key={`entry_${i}`}>
                <Link to={`/blog/${entry.node.uid}/`}>
                  <div className="relative">
                    <div className={`absolute z-10 right-0 px-3 pb-3 center h-full text-white bg-red opacity-1`}>
                      <div className="flex h-full items-end">
                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-12 w-12" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                      </div>
                    </div>
                    <GatsbyImage image={entry.node.data.image.gatsbyImageData} alt={entry.node.data.heading.text} className="h-64" style={{ width: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                  </div>
                  <div className="font-ubuntu text-black text-2xl leading-tight mt-6 mb-3">
                    <h2 className="border-b-2 border-white group-hover:border-b-2 group-hover:border-black inline">{entry.node.data.heading.text}</h2>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query {
  prismicBlogPage {
    data {
      page_title
      meta_description
      heading {
        text
      }
    }
  }
  allPrismicBlog {
    edges {
      node {
        uid
        last_publication_date(formatString: "MMM DD, YYYY")
        data {
          text {
            html
          }
          heading {
            text
          }
          image {
            gatsbyImageData(width: 640, height: 640)
          }
        }
      }
    }
  }
}
`

export default BlogPage
